import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import Gallery from "../../components/Gallery/Gallery";

const gallery = () => {
	return (
		<MainLayout lang="en" title="home">
			<Gallery />
		</MainLayout>
	);
};

export default gallery;
